<script>
const decimalFormat = new Intl.NumberFormat('th-TH', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })
</script>
<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import {useToast} from "primevue/usetoast";
// import {useConfirm} from "primevue/useconfirm";
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";
import {CalculatorStore} from "@/uhas/store/calculator";
import {Countdown} from 'vue3-flip-countdown';
import moment from "moment";

const toast = useToast();
// const confirm = useConfirm();

const memberStore = MemberStore();
const calculatorStore = CalculatorStore();
const {activity, memberData} = storeToRefs(memberStore);
const {configs} = storeToRefs(calculatorStore);

/**
 * Data
 */
const loading = ref(false);
const blockOverlay = ref(false);
const confirmExnessLinkAccountDialog = ref(false);

const exnessClientIdState = reactive({exnessClientID: null});
const exnessClientIdValidation = useVuelidate({
	exnessClientID: { required }
}, exnessClientIdState);
const exnessClientIdSubmitted = ref(false);
const tempExness = ref(null);

const confirmXmLinkAccountDialog = ref(false);
const xmClientIdState = reactive({login_id: null});
const xmClientIdValidation = useVuelidate({
	login_id: { required }
}, xmClientIdState);
const xmClientIdSubmitted = ref(false);
const tempXm = ref(null);

const confirmExnessCompeteAccountDialog = ref(false);
const exnessAccountInfo = ref(null);
const exnessAccountState = reactive({
	provider: "exness",
	id: null,
	server: null,
	login: null,
	password: null
});
const exnessAccountValidation = useVuelidate({
	id: { required },
	server: { required },
	login: { required },
	password: { required }
}, exnessAccountState);
const exnessAccountSubmitted = ref(false);

const confirmXmCompeteAccountDialog = ref(false);
const xmAccountInfo = ref(null);
const xmAccountState = reactive({
	provider: "xm",
	id: null,
	server: null,
	login: null,
	password: null
});
const xmAccountValidation = useVuelidate({
	id: { required },
	server: { required },
	login: { required },
	password: { required }
}, xmAccountState);
const xmAccountSubmitted = ref(false);

/**
 * Computed
 */
const activityFinished = computed(() => activity?.value && new Date() > new Date(activity.value.finished_at));

/**
 * Watch
 */
watch(exnessAccountInfo, val => {
	// const regEx = new RegExp(/\S+:\s*(?<url>[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b[-a-zA-Z0-9()@:%_+.~#?&//=]*)\r*\n*\S+:\s*(?<login>\d{8,})\r*\n*\S+:\s*(?<password>\S+)/, "gm");
	const regEx = new RegExp(/\S+:\s*(?<url>\S*)\n+\S+:\s*(?<login>\S*)\n+\S+:\s*(?<password>\S*)/, "gm");
	const matches = regEx.exec(val);
	if (matches) {
		exnessAccountState.server = matches[1];
		exnessAccountState.login = matches[2];
		exnessAccountState.password = matches[3];
	} else {
		exnessAccountState.server = null;
		exnessAccountState.login = null;
		exnessAccountState.password = null;
	}
	// console.log(exnessAccountState)
});
watch(xmAccountInfo, val => {
	const regEx = new RegExp(/\S+:\s*(?<url>\S*)\n+\S+:\s*(?<login>\S*)\n+\S+:\s*(?<password>\S*)/, "gm");
	const matches = regEx.exec(val);
	if (matches) {
		xmAccountState.server = matches[1];
		xmAccountState.login = matches[2];
		xmAccountState.password = matches[3];
	} else {
		xmAccountState.server = null;
		xmAccountState.login = null;
		xmAccountState.password = null;
	}
});

/**
 * Mounted
 */
onMounted(() => {
	calculatorStore.getConfigs();
	memberStore.getMainActivity()
		.then(() => {
			exnessAccountState.id = activity?.value?.id;
			xmAccountState.id = activity?.value?.id;

			const _activities = memberStore.user?.activities
			if (typeof _activities !== 'undefined') {
				const info = _activities[activity.value.id];
				if (activity?.value?.id && info) {
					if (info.exness) {
						exnessAccountInfo.value = `เซิร์ฟเวอร์: ${info.exness.server || ''}\nเลขบัญชี: ${info.exness.login || ''}\nรหัสผ่านนักลงทุน: ${info.exness.password || ''}`;
					}
					if (info.xm) {
						xmAccountInfo.value = `เซิร์ฟเวอร์: ${info.xm.server || ''}\nเลขบัญชี: ${info.xm.login || ''}\nรหัสผ่านนักลงทุน: ${info.xm.password || ''}`;
					}
				}
			}

			// memberStore.snapActivity(activity?.value?.id);
		})
		.catch(err => {
			console.log(err)
		});
});

/**
 * Actions
 */
const requestLinkWithExness = async () => {
	exnessClientIdSubmitted.value = true;
	if (exnessClientIdValidation.value.$invalid) {
		if (exnessClientIdValidation.value.id.$invalid) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: "Activity info invalid, Please reload the page and try again.",
				life: 10000
			});
		}

		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getExnessAccount({...exnessClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "member-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Exness's account has been linked with another member." : "Cannot get Exness's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempExness.value = result.exness;
	confirmExnessLinkAccountDialog.value = true;
}
const confirmExnessLinkAccount = async () => {
	if (!tempExness.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Client ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkExnessAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Exness's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.exness = result.exness;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Exness's account has been linked.",
			life: 10000
		});
	}
}

const requestLinkWithXm = async () => {
	xmClientIdSubmitted.value = true;
	if (xmClientIdValidation.value.$invalid) {
		return
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.getXmAccount({...xmClientIdState})
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		if([901, 902].includes(result.error.code)) {
			toast.add({
				severity:"error",
				summary: "Error Message",
				group: "member-html",
				code: result.error.code,
				life: 20000
			});
		} else {
			const msg = result.error.message || (result.error.code === 423 ? "The Xm's account has been linked with another member." : "Cannot get Xm's account info.");
			toast.add({
				severity:"error",
				summary: "Error Message",
				detail: msg,
				life: 10000
			});
		}

		return;
	}

	tempXm.value = result.xm;
	confirmXmLinkAccountDialog.value = true;
}
const confirmXmLinkAccount = async () => {
	if (!tempXm.value) {
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: "Please specify the Login ID before linking the account.",
			life: 10000
		});

		return;
	}

	blockOverlay.value = true;
	loading.value = true;
	const result = await memberStore.confirmLinkXmAccount()
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			loading.value = false;
			blockOverlay.value = false;
		});
	if (result.error) {
		const msg = result.error.message || `Cannot link Xm's account. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg,
			life: 10000
		});
	} else {
		memberStore.user.xm = result.xm;

		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Your Xm's account has been linked.",
			life: 10000
		});

		confirmXmLinkAccountDialog.value = false;
	}
}

const registerCompete = (provider) => {
	if (provider === 'exness') {
		confirmExnessCompeteAccountDialog.value = true;
	} else if (provider === 'xm') {
		confirmXmCompeteAccountDialog.value = true;
	}
}
const confirmCompete = async (provider) => {
	let accountState = exnessAccountState;
	if (provider === 'exness') {
		exnessAccountSubmitted.value = true;
		if (exnessAccountValidation.value.$invalid) {
			return
		}
	} else if (provider === 'xm') {
		xmAccountSubmitted.value = true;
		if (xmAccountValidation.value.$invalid) {
			return
		}
		accountState = xmAccountState;
	}

	loading.value = true;
	const result = await memberStore.confirmCompete(accountState)
		.then(result => result)
		.catch(err => err)
		.finally(() => {
			if (provider === 'exness') {
				exnessAccountInfo.value = `เซิร์ฟเวอร์: ${exnessAccountState.server || ''}\nเลขบัญชี: ${exnessAccountState.login || ''}\nรหัสผ่านนักลงทุน: ${exnessAccountState.password || ''}`;
				confirmExnessCompeteAccountDialog.value = false;
			} else if (provider === 'xm') {
				xmAccountInfo.value = `เซิร์ฟเวอร์: ${xmAccountState.server || ''}\nเลขบัญชี: ${xmAccountState.login || ''}\nรหัสผ่านนักลงทุน: ${xmAccountState.password || ''}`;
				confirmXmCompeteAccountDialog.value = false;
			}

			loading.value = false;
		});
		console.log(result)
	if (result.error) {
		const msg = result.error.message || `Cannot complete the process. ${result.error.code || ""}`;
		toast.add({
			severity:"error",
			summary: "Error Message",
			detail: msg === "Data fxbook not found." ? "กรุณาระบุข้อมูลให้ถูกต้อง" : msg,
			life: 10000
		});
	} else {
		toast.add({
			severity:"success",
			summary: "Success Message",
			detail: "Process completed.",
			life: 10000
		});
	}
}
</script>

<style lang="scss">
.block-overlay {
	box-shadow: unset;

	.p-dialog-content {
		background: transparent;
    overflow: hidden;

		circle {
			fill: none;
		}
	}
}
</style>
<style lang="scss" scoped>
:v-deep(.block-overlay) {
	box-shadow: unset;
	.p-dialog-content {
		background: transparent;
    overflow: hidden;
	}
}

.avatar-badge {
	width: 24px;
	height: 24px;
	border: 2px solid white;
	position: absolute;
	bottom: 2%;
	right: 2%;
	border-radius: 100%;
}

::v-deep .p-card-body {
	padding: 2rem;
}

.deadline::v-deep {
	.p-blockui.p-component-overlay {
		background-color: rgba(255,255,255, 0.9) !important;
	}
}
.activity-desc {
	font-family: inherit;
	white-space: pre-wrap;
}
.accountInfo::placeholder {
	color: #bbb;
}
</style>

<template>
	<Toast />
	<Toast group="member-html">
		<template #message="slotProps">
				<div class="flex flex-column">
						<div>
								<!-- <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i> -->
								<h4>{{slotProps.message.summary}}</h4>
								<p v-if="slotProps.message.code === 902">
									คุณไม่สามารถเชื่อมต่อได้เนื่องจากคุณได้มีการย้ายโบรกเกอร์แล้ว
								</p>
								<p v-else>
									ไม่พบเลขบัญชี ในระบบของเรา กรุณาติดต่อแอดมินในไลน์
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">@uhasthailand</a> หรือ
									<a href='https://lin.ee/1eoK6Hd' target='_blank' class="text-900 text-xl font-bold">คลิก</a></p>
						</div>
				</div>
		</template>
	</Toast>

	<ConfirmPopup />
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<Dialog
		v-model:visible="confirmExnessLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<label class="block text-900 font-medium mb-2" for="address">เลขบัญชีจริง exness</label>
		<InputText v-model="tempExness.client_account" id="address" type="text" class="w-full mb-3" readonly />

		<template #footer>
			<Button
				v-if="!memberStore.user?.exness"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี exness: ${tempExness.client_account}`"
				@click="confirmExnessLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmXmLinkAccountDialog"
		:closeOnEscape="false"
		header=" "
		modal
	>
		<template #footer>
			<Button
				v-if="!memberStore.user?.xm"
				icon="pi pi-link"
				:loading="loading"
				:disabled="loading"
				:label="`ยืนยันการผูกบัญชี xm: ${tempXm.loginId}`"
				@click="confirmXmLinkAccount($event)"
			/>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmExnessCompeteAccountDialog"
		:closeOnEscape="false"
		:style="{width: '375px'}"
		header="ยืนยันบัญชีแข่งเทรด Exness"
		modal
	>
		<!-- <p class="mb-1">เซิร์ฟเวอร์: {{exnessAccountState.server || ""}}</p>
		<p class="mb-1">เลขบัญชี: {{exnessAccountState.login || ""}}</p>
		<p>รหัสผ่านนักลงทุน: {{exnessAccountState.password || ""}}</p> -->

		<div class="field grid">
			<label for="server" class="col-12 mb-2">เซิร์ฟเวอร์:</label>
			<div class="col-12">
				<InputText
					id="server"
					v-model="exnessAccountValidation.server.$model"
					class="w-full p-2"
					type="text"
					placeholder="เซิร์ฟเวอร์"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="exnessAccountValidation.server.$invalid && exnessAccountSubmitted" class="p-error">
						กรุณาระบุเซิร์ฟเวอร์
					</small>
				</div>
			</div>
		</div>
		<div class="field grid">
			<label for="login" class="col-12 mb-2">เลขบัญชี:</label>
			<div class="col-12">
				<InputText
					id="login"
					v-model="exnessAccountValidation.login.$model"
					class="w-full p-2"
					type="text"
					placeholder="เลขบัญชี"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="exnessAccountValidation.login.$invalid && exnessAccountSubmitted" class="p-error">
						กรุณาระบุเลขบัญชี
					</small>
				</div>
			</div>
		</div>
		<div class="field grid">
			<label for="password" class="col-12 mb-2">รหัสผ่านนักลงทุน:</label>
			<div class="col-12">
				<InputText
					id="password"
					v-model="exnessAccountValidation.password.$model"
					class="w-full p-2"
					type="text"
					placeholder="รหัสผ่านนักลงทุน"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="exnessAccountValidation.password.$invalid && exnessAccountSubmitted" class="p-error">
						กรุณาระบุรหัสผ่านนักลงทุน
					</small>
				</div>
			</div>
		</div>

		<template #footer>
			<div class="text-center">
				<Button
					:loading="loading"
					:disabled="loading"
					label="ลงทะเบียนแข่งแทรด Exness"
					@click="confirmCompete('exness')"
				/>
			</div>
		</template>
	</Dialog>

	<Dialog
		v-model:visible="confirmXmCompeteAccountDialog"
		:closeOnEscape="false"
		:style="{width: '375px'}"
		header="ยืนยันบัญชีแข่งเทรด XM"
		modal
	>
		<!-- <p class="mb-1">เซิร์ฟเวอร์: {{exnessAccountState.server || ""}}</p>
		<p class="mb-1">เลขบัญชี: {{exnessAccountState.login || ""}}</p>
		<p>รหัสผ่านนักลงทุน: {{exnessAccountState.password || ""}}</p> -->

		<div class="field grid">
			<label for="server" class="col-12 mb-2">เซิร์ฟเวอร์:</label>
			<div class="col-12">
				<InputText
					id="server"
					v-model="xmAccountValidation.server.$model"
					class="w-full p-2"
					type="text"
					placeholder="เซิร์ฟเวอร์"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="xmAccountValidation.server.$invalid && xmAccountSubmitted" class="p-error">
						กรุณาระบุเซิร์ฟเวอร์
					</small>
				</div>
			</div>
		</div>
		<div class="field grid">
			<label for="login" class="col-12 mb-2">เลขบัญชี:</label>
			<div class="col-12">
				<InputText
					id="login"
					v-model="xmAccountValidation.login.$model"
					class="w-full p-2"
					type="text"
					placeholder="เลขบัญชี"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="xmAccountValidation.login.$invalid && xmAccountSubmitted" class="p-error">
						กรุณาระบุเลขบัญชี
					</small>
				</div>
			</div>
		</div>
		<div class="field grid">
			<label for="password" class="col-12 mb-2">รหัสผ่านนักลงทุน:</label>
			<div class="col-12">
				<InputText
					id="password"
					v-model="xmAccountValidation.password.$model"
					class="w-full p-2"
					type="text"
					placeholder="รหัสผ่านนักลงทุน"
					:disabled="loading"
				/>
				<div class="text-right">
					<small v-if="xmAccountValidation.password.$invalid && xmAccountSubmitted" class="p-error">
						กรุณาระบุรหัสผ่านนักลงทุน
					</small>
				</div>
			</div>
		</div>

		<template #footer>
			<div class="text-center">
				<Button
					:loading="loading"
					:disabled="loading"
					label="ลงทะเบียนแข่งแทรด XM"
					@click="confirmCompete('xm')"
				/>
			</div>
		</template>
	</Dialog>

	<Card>
		<template #title>
      <!-- แข่งเทรดเงินปลอม แจกเงินจริง 150,000 บาท -->
			{{activity && activity.heading ? activity.heading : ""}}

			<div
				v-if="activity?.total_competitor"
				class="mt-5 text-xl"
			>
				(จำนวนผู้เข้าร่วม {{ decimalFormat.format(activity?.total_competitor) }} บัญชี)
			</div>
    </template>
		<template #content>
			<div
				v-if="activity"
				class="deadline my-4 w-max max-w-full"
			>
				<!-- <BlockUI :blocked="activityFinished">
					<Countdown
						v-if="activity && activity.finished_at"
						:deadline="moment(activity.finished_at).format('YYYY-MM-DD HH:mm:ss')"
					/>
				</BlockUI> -->
				<div v-if="activityFinished">
					<hr>
					<span class="font-bold text-4xl sm:text-6xl md:text-7xl">หมดเวลาร่วมกิจกรรม</span>
					<hr>
				</div>
				<div v-else>
					<Countdown
						v-if="activity.finished_at"
						:deadline="moment(activity.finished_at).format('YYYY-MM-DD HH:mm:ss')"
					/>
				</div>
			</div>
			<div v-if="!memberStore.user?.exness && !memberStore.user?.xm">
				<!-- <p class="text-xl">กิจกรรมแข่งเทรดเงินบัญชีเงินปลอม ชนะรับเงินจริงไปเลย 150,000 บาท</p> -->
				<p class="text-lg">วิธีเข้าร่วมง่ายๆ แค่ 2 ขั้นตอน</p>
				<p class="text-lg">
					1. เพียงแค่เปิดบัญชี exness ผ่านลิงค์ข้างล่างนี้<br>
					Exness: <a href="https://one.exness-track.com/a/pf36v6cu" target="_blank">https://one.exness-track.com/a/pf36v6cu</a><br>
					XM: <a href="https://clicks.pipaffiliates.com/c?c=503827&l=th&p=0" target="_blank">https://clicks.pipaffiliates.com/c?c=503827&l=th&p=0</a><br><br>

					2. หากมีบัญชี exness หรือ xm อยู่แล้วกรุณาติดต่อแอดมินในไลน์<br>
					<a href="https://lin.ee/1eoK6Hd" target="_blank">@uhasthailand</a> หรือ <a href="https://lin.ee/1eoK6Hd" target="_blank">https://lin.ee/1eoK6Hd</a>
				</p>
				<div class="mt-6 field w-max max-w-full">
					<div class="p-inputgroup">
						<InputText
							v-model="exnessClientIdValidation.exnessClientID.$model"
							placeholder="เลขบัญชีจริง exness"
							:class="{'p-error': exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted}"
						/>
						<Button
							:loading="loading"
							:disabled="loading"
							icon="pi pi-link"
							class="p-button-outlined"
							label="ผูกบัญชี exness"
							@click="requestLinkWithExness"
						/>
					</div>
					<!-- <InlineMessage>Exness Client ID is required</InlineMessage> -->
					<small v-if="(exnessClientIdValidation.exnessClientID.$invalid && exnessClientIdSubmitted) || exnessClientIdValidation.exnessClientID.$pending.$response" class="p-error">
						{{exnessClientIdValidation.exnessClientID.required.$message.replace("Value", "เลขบัญชีจริง exness")}}
					</small>
				</div>

				<div class="my-6 field w-max max-w-full">
						<div class="p-inputgroup">
							<InputText
								v-model="xmClientIdValidation.login_id.$model"
								placeholder="เลขบัญชีจริง xm"
								:class="{'p-error': xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted}"
							/>
							<Button
								:loading="loading"
								:disabled="loading"
								icon="pi pi-link"
								class="p-button-outlined"
								label="ผูกบัญชี xm"
								@click="requestLinkWithXm"
							/>
						</div>
						<small v-if="(xmClientIdValidation.login_id.$invalid && xmClientIdSubmitted) || xmClientIdValidation.login_id.$pending.$response" class="p-error">
							{{xmClientIdValidation.login_id.required.$message.replace("Value", "เลขบัญชีจริง xm")}}
						</small>
					</div>
			</div>

			<!-- <p class="text-lg">ลงทะเบียนได้แล้ววันนี้ถึง 31 มกราคม 2023  กิจกรรมเริ่ม 1-28 ภุมภาพันธ์ 2023</p>
			<p class="text-lg">กติกาในการนับคะแนนจะประกาศวันที่ 15 มกราคม 2023 จะนับด้วยผลกำไรมากที่สุด ลบด้วยความเสี่ยง</p> -->
			<pre
				v-if="activity"
				class="activity-desc text-lg"
			>
{{activity && activity.description ? activity.description : ""}}
			</pre>

			<!-- <iframe
				v-if="activity && activity.link_url"
				widths="560"
				heights="auto"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				class="w-full h-15rem sm:w-30rem sm:h-20rem"
				:src="activity.link_url"
				allowfullscreen>
			</iframe> -->
			<a
				v-if="activity"
				:href="activity?.link_url || '#'"
				target="_blank"
			>
				<img
					:src="activity?.picture_url || ''"
					class="w-full md:w-10 lg:w-6 xl:w-5"
				/>
			</a>

			<div v-if="memberStore.user?.exness || memberStore.user?.xm">
				<div class="my-6">
					<div
						v-if="memberStore.user?.exness"
						class="font-bold text-xl"
					>
						บัญชี Exness: <span class="underline mr-3">{{memberStore.user?.exness.from_exness_id}}</span> เป็นสมาชิกเรียบร้อยแล้ว

						<template v-if="activity">
							<div class="field mt-1 mb-0 w-full md:w-10 lg:w-6 xl:w-5">
								<label class="block text-700 font-medium mb-2" for="exnessAccountInfo">กรุณากรอกข้อมูลบัญชีแข่งของ Exness ที่คัดลอกมา</label>
								<Textarea
									v-model="exnessAccountInfo"
									class="accountInfo w-full"
									id="exnessAccountInfo"
									rows="5"
									:class="{'p-error': false && exnessAccountValidation.$invalid && exnessAccountSubmitted}"
									:placeholder="`เซิร์ฟเวอร์: ...\nเลขบัญชี: ...\nรหัสผ่านนักลงทุน: ...`"
									:disabled="activityFinished"
								/>
								<!-- <div class="text-right">
									<small v-if="exnessAccountValidation.$invalid && exnessAccountSubmitted" class="p-error">
										กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน
									</small>
								</div> -->
							</div>
							<Button
								v-if="!activityFinished"
								class="mb-6"
								:label="
									memberStore.user?.activities
									? (memberStore.user.activities[activity?.id] ? 'แก้ไขข้อมูลแข่งเทรด' : 'ลงทะเบียนแข่งเทรด')
									: 'ลงทะเบียนแข่งเทรด'
								"
								:loading="loading"
								:disabled="loading"
								@click="registerCompete('exness')"
							/>
						</template>

						<Card
							v-if="configs?.uhasActivityActive?.stats_show === true && memberData.activities && memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats"
							class="shadow-4 mt-1"
							style="max-width: 600px;"
						>
							<template #title>
								Your Exness activity info
								(Rank: {{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['ranking'] }})
							</template>
							<template #content>
								<table>
									<tr>
										<td>Gain</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['gain'] }}</td>
									</tr>
									<tr>
										<td>Abs. Gain</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['absGain'] }}</td>
									</tr>
									<tr>
										<td>Daily</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['daily'] }}</td>
									</tr>
									<tr>
										<td>Monthly</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['monthly'] }}</td>
									</tr>
									<tr>
										<td>Withdrawals</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['withdrawals'] }}</td>
									</tr>
									<tr>
										<td>Deposits</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['deposits'] }}</td>
									</tr>
									<tr>
										<td>Interest</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['interest'] }}</td>
									</tr>
									<tr>
										<td>Profit</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['profit'] }}</td>
									</tr>
									<tr>
										<td>Balance</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['balance'] }}</td>
									</tr>
									<tr>
										<td>Drawdown</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['drawdown'] }}</td>
									</tr>
									<tr>
										<td>Equity</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['equity'] }}</td>
									</tr>
									<tr>
										<td>Equity</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['equityPercent'] }}</td>
									</tr>
									<tr>
										<td>Demo</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['demo'] }}</td>
									</tr>
									<tr>
										<td>Last Update Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['lastUpdateDate'] }}</td>
									</tr>
									<tr>
										<td>Creation Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['creationDate'] }}</td>
									</tr>
									<tr>
										<td>First Trade Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['firstTradeDate'] }}</td>
									</tr>
									<tr>
										<td>Tracking</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['tracking'] }}</td>
									</tr>
									<tr>
										<td>Views</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['views'] }}</td>
									</tr>
									<tr>
										<td>Commission</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['commission'] }}</td>
									</tr>
									<tr>
										<td>Currency</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['currency'] }}</td>
									</tr>
									<tr>
										<td>Profit Factor</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['profitFactor'] }}</td>
									</tr>
									<tr>
										<td>Pips</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['pips'] }}</td>
									</tr>
									<tr>
										<td>Invitation Url</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.exness?.stats['invitationUrl'] }}</td>
									</tr>
								</table>
							</template>
						</Card>
					</div>

					<div
						v-if="memberStore.user?.xm"
						class="font-bold text-xl mt-6"
					>
						Xm: <span class="underline">{{memberStore.user?.xm.login_id}}</span> เป็นสมาชิกเรียบร้อยแล้ว

						<template v-if="activity">
							<div class="field mt-1 mb-0 w-full md:w-10 lg:w-6 xl:w-5">
								<label class="block text-700 font-medium mb-2" for="xmAccountInfo">กรุณากรอกข้อมูลบัญชีแข่งของ XM ที่คัดลอกมา</label>
								<Textarea
									v-model="xmAccountInfo"
									class="accountInfo w-full"
									id="xmAccountInfo"
									rows="5"
									:class="{'p-error': false && xmAccountValidation.$invalid && xmAccountSubmitted}"
									:placeholder="`เซิร์ฟเวอร์: ...\nเลขบัญชี: ...\nรหัสผ่านนักลงทุน: ...`"
									:disabled="activityFinished"
								/>
							</div>
							<Button
								v-if="!activityFinished"
								class="mb-6"
								:label="
									memberStore.user?.activities
									? (memberStore.user.activities[activity?.id] ? 'แก้ไขข้อมูลแข่งเทรด' : 'ลงทะเบียนแข่งเทรด')
									: 'ลงทะเบียนแข่งเทรด'
								"
								:loading="loading"
								:disabled="loading"
								@click="registerCompete('xm')"
							/>
						</template>

						<Card
							v-if="configs?.uhasActivityActive?.stats_show === true && memberData.activities && memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats"
							class="shadow-4 mt-1"
							style="max-width: 600px;"
						>
							<template #title>
								Your XM activity info
								(Rank: {{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['ranking'] }})
							</template>
							<template #content>
								<table>
									<tr>
										<td>Gain</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['gain'] }}</td>
									</tr>
									<tr>
										<td>Abs. Gain</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['absGain'] }}</td>
									</tr>
									<tr>
										<td>Daily</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['daily'] }}</td>
									</tr>
									<tr>
										<td>Monthly</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['monthly'] }}</td>
									</tr>
									<tr>
										<td>Withdrawals</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['withdrawals'] }}</td>
									</tr>
									<tr>
										<td>Deposits</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['deposits'] }}</td>
									</tr>
									<tr>
										<td>Interest</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['interest'] }}</td>
									</tr>
									<tr>
										<td>Profit</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['profit'] }}</td>
									</tr>
									<tr>
										<td>Balance</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['balance'] }}</td>
									</tr>
									<tr>
										<td>Drawdown</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['drawdown'] }}</td>
									</tr>
									<tr>
										<td>Equity</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['equity'] }}</td>
									</tr>
									<tr>
										<td>Equity</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['equityPercent'] }}</td>
									</tr>
									<tr>
										<td>Demo</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['demo'] }}</td>
									</tr>
									<tr>
										<td>Last Update Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['lastUpdateDate'] }}</td>
									</tr>
									<tr>
										<td>Creation Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['creationDate'] }}</td>
									</tr>
									<tr>
										<td>First Trade Date</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['firstTradeDate'] }}</td>
									</tr>
									<tr>
										<td>Tracking</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['tracking'] }}</td>
									</tr>
									<tr>
										<td>Views</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['views'] }}</td>
									</tr>
									<tr>
										<td>Commission</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['commission'] }}</td>
									</tr>
									<tr>
										<td>Currency</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['currency'] }}</td>
									</tr>
									<tr>
										<td>Profit Factor</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['profitFactor'] }}</td>
									</tr>
									<tr>
										<td>Pips</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['pips'] }}</td>
									</tr>
									<tr>
										<td>Invitation Url</td><td> : </td>
										<td>{{ memberData.activities[configs?.uhasActivityActive?.activity_id]?.xm?.stats['invitationUrl'] }}</td>
									</tr>
								</table>
							</template>
						</Card>
					</div>
				</div>

				<p class="mt-3 text-lg">
					กรุณาแอดไลน์
					<a href="https://lin.ee/1eoK6Hd" target="_blank">@uhasthailand</a> หรือ <a href="https://lin.ee/1eoK6Hd" target="_blank">https://lin.ee/1eoK6Hd</a>
				</p>
				<p class="text-lg">
					กรุณากรอกข้อมูลเพิ่มเติม เพื่อให้ Uhas ได้มอบของขวัญเล็กๆ น้อยๆ ให้กับสมาชิกของเรา
					<router-link to="/profile" class="logo">คลิก</router-link>
				</p>
			</div>
		</template>
	</Card>
</template>
